export const apiUrlMap = {
  "development": {
    "authenticationHandler": "https://cxs4j7khkuzsbypy7efmlusy4i0afyyh.lambda-url.us-west-2.on.aws/",
    "collectionHandler": "https://zzg7bfqrygw53gaplpiyu4jova0rjnfw.lambda-url.us-west-2.on.aws/",
    "deviceHandler": "https://e3u6lntajq56j5kpe5gvfw4uye0yakoa.lambda-url.us-west-2.on.aws/",
    "listReferrals": "https://qz3pwxrfzwuxqmaaqnptaaxh7u0lpgdq.lambda-url.us-west-2.on.aws/",
    "qrGenerationHandler": "https://lxztskfmmr35ae6ukyq6gnwkei0ehkxc.lambda-url.us-west-2.on.aws/"
  },
  "production": {
    "authenticationHandler": "https://5ivdywg6des2gg7yfc37nsqnwy0wioim.lambda-url.us-east-1.on.aws/",
    "collectionHandler": "https://xqobowpxyjqt2pctxv4ti6iewi0uimcq.lambda-url.us-east-1.on.aws/",
    "deviceHandler": "https://ak6a7afxjjmpkbciqgy7jcmxya0ltbri.lambda-url.us-east-1.on.aws/",
    "listReferrals": "https://efaqis7rk742soe54appspt7oa0nmsqv.lambda-url.us-east-1.on.aws/",
    "qrGenerationHandler": "https://ivgkhvagbnen7zc7eyefvx3mdu0ywcmq.lambda-url.us-east-1.on.aws/"
  },
  "local": {
    "authenticationHandler": "https://localhost:5000/func/AuthenticationHandler/",
    "collectionHandler": "localhost:5000/func/CollectionHandler/",
    "deviceHandler": "localhost:5000/func/DeviceHandler/",
    "listReferrals": "localhost:5000/func/ListReferrals/",
    "testNewArchitecture": "http://localhost:5000/func/TestNewArchitecture/"
  },
  "stage": {
    "authenticationHandler": "https://cwctfwiarortm2p4gaue425bzq0dobay.lambda-url.us-west-2.on.aws/",
    "collectionHandler": "https://e5zunpy6eisv7oudhss2avwegi0zbivs.lambda-url.us-west-2.on.aws/",
    "deviceHandler": "https://yuaq33su76uuv4s3xub22cpiym0dyjxp.lambda-url.us-west-2.on.aws/",
    "listReferrals": "https://ahcimvwcqvcgkvmykgg4rm7t2q0yjvif.lambda-url.us-west-2.on.aws/",
    "qrGenerationHandler": "https://aox466gaweg7xvievk56ebpdzm0miofe.lambda-url.us-west-2.on.aws/"
  },
  "test": {
    "authenticationHandler": "https://cf7vx6jcykl5o6yaukkmk4wxmq0qliuo.lambda-url.us-west-2.on.aws/",
    "collectionHandler": "https://cpwngbjzcrpydxx6futqroclkm0hajkq.lambda-url.us-west-2.on.aws/",
    "deviceHandler": "https://l6ge3am4w4kekjfvc5u6dyursa0tlqtv.lambda-url.us-west-2.on.aws/",
    "listReferrals": "https://6dsol3ebyj6wpe7neigfuhvdqm0submj.lambda-url.us-west-2.on.aws/",
    "qrGenerationHandler": "https://7ckzdqwuz26gykridkcuidqumy0dwhym.lambda-url.us-west-2.on.aws/"
  }
};
